<!--
 * @Descripttion:渲染Markdown文档组件
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-08-04 11:16:50
--> 

<template>
  <div id="my-markdown" class="markdown-body">
	<div class="cmsNav" v-if="moduleTitle && cmsTitle">首页 > {{ moduleTitle }} > {{ cmsTitle }}</div>
	<div v-if="loading" class="loading-cms" style="font-size: 32px">
		<i class="el-icon-loading"></i>
	</div>
	<template v-else>
		<vue-markdown :source="notice"></vue-markdown>
	</template>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import * as noticeAPI from '@/api/notice'
export default {
  name: 'MyMarkdown',
  components: {
    VueMarkdown
  },
  data() {
	return {
		notice: '',
		id: 0,
		moduleTitle: '',
		cmsTitle: '',
		loading: true
	}
  },
  created() {
	if (this.$route.query.id != undefined) {
		this.id = this.$route.query.id
	}
	this.loading = true
    noticeAPI
      .showNotice(this.id)
      .then(res => {
        if (res.status_code === 200) {
			this.notice = res.data.cms.content.content
			this.moduleTitle = res.data.module.label
			this.cmsTitle = res.data.cms.title
			this.loading = false
        } else {
			this.loading = false
			this.notifyError('获取公告失败', res.msg)
        }
      })
      .catch(err => {
        this.notifyError('获取公告失败', err)
      })
  }
}
</script>
<style scoped>
@import '../assets/css/github-markdown.css';
.markdown-body {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 30px;
}
.cmsNav {
	line-height: 30px;
	padding-bottom: 18px;
	padding-top: 30px;
}
.loading-cms{
	text-align: center;
	color: #666;
	padding: 10px 0 10px;
}
</style>